<template lang="pug">
	window
		template(slot="header")
			span Confirmacion de correo

		v-container(v-if="loading")
			v-layout(column align-center)
				v-flex
					v-progress-circular(indeterminate size="80" :width="4" color="primary")

		div(v-else)
			div(v-if="invalid")
				p.
					Hubo un problema para verificar tu correo. Por favor comunicate con nosotros a travs

			div(v-else)
				p.
					Has confirmado tu correo electronico. Ya puedes accesar la aplicacion

			div(class="text-xs-right")
				router-link(:to="{name: 'login'}") Iniciar sesion

</template>

<script>
import Window from '@/modules/access/components/Window.component'
import firebase from '@/modules/common/firebase';

export default {
  name: 'AccessEmailConfirmation',
	components: {
		Window
	},

	data() {
		return {
			code: this.$route.query.oobCode,
			loading: true,
			invalid: false
		}
	},

	mounted() {
		this.verifyUser();
	},

	methods: {
		verifyUser() {
			if(!this.code) {
				this.loading = false;
				this.invalid = true;

			} else {
				firebase.auth().applyActionCode(this.code).then(this.onVerifyEmailSuccess).catch(this.onVerifyEmailFailure)
			}
		},

		onVerifyEmailSuccess() {
			this.loading = false;
		},

		onVerifyEmailFailure() {
			this.loading = false;
			this.invalid = true;
		}
	}
}
</script>


